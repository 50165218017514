import Autonav from './js/blocks/autonav';

export default class App {
    constructor() {
        document.addEventListener('DOMContentLoaded', () => {
            this.initialize();
        });
    }

    initialize = () => {
        new Autonav();
    };
}

window.App = new App();

