import 'slicknav/jquery.slicknav';
import 'slicknav/scss/slicknav.scss';
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

export default class Autonav {
    constructor() {
        this.header = document.querySelector('.header');
        this.autonav = this.header.querySelector('.autonav');
        this.bindEvents();
        this.initializeNav();
    }

    bindEvents = () => {
        window.addEventListener('load', this.initializeNav());
    };

    initializeNav = () => {
        $('.header__menu').slicknav({
            'appendTo': $('.header'),
            'label': '',
        });
    };

};




